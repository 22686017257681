
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import { useStore } from "vuex";
import { Site } from "@/core/model/site";
import { useRoute } from "vue-router";
import MutualInfo from "@/views/mutuales/MutualInfo.vue";
import MutualSiteFormModal from "@/views/mutuales/MutualSiteFormModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "admin-mutual-site",
  components: {
    MutualSiteFormModal,
    MutualInfo,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const site = ref<Site>({} as Site);
    const condition = ref<string>("none");
    const openModal = ref<number>(0);
    const loading = ref<boolean>(false);
    const loadingStatus = ref<boolean>(false);
    onMounted(() => {
      setCurrentPageActions(false, false, []);
      setCurrentPageBreadcrumbs("Administrar Mutual", ["Mutuales", "Sitio"]);
      loadData();
    });

    const loadData = () => {
      loading.value = true;
      store.dispatch(Actions.ADMIN_SITE_GET, route.params.uuid).then(() => {
        condition.value =
          typeof store.state.AdminSiteModule.site.name !== "undefined"
            ? "edit"
            : "new";
        site.value = store.state.AdminSiteModule.site;
        loading.value = false;
      });
    };

    const formatDateTime = (value) => {
      return value === undefined
        ? ""
        : moment(value).format("DD/MM/YYYY hh:mm a");
    };

    const showModal = () => {
      openModal.value++;
    };

    const confirmChangeStatus = () => {
      Swal.fire({
        text:
          "¿Confirma que desea " +
          (site.value.active ? "desactivar" : "activar") +
          " el sitio?",
        icon: "question",
        buttonsStyling: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          changeStatusSite();
        }
      });
    };

    const changeStatusSite = () => {
      loadingStatus.value = true;
      store
        .dispatch(Actions.ADMIN_SITE_CHANGE_STATUS, {
          site: site.value.uuid,
          active: !site.value.active,
        })
        .then(() => {
          loadingStatus.value = false;
          ToastService.show("Estado actualizado con éxito", "success");
          loadData();
        })
        .catch((error) => {
          displayErrors(store, "AdminSiteModule");
          loadingStatus.value = false;
        });
    };

    return {
      formatDateTime,
      site,
      condition,
      loadData,
      openModal,
      showModal,
      confirmChangeStatus,
      loading,
      loadingStatus,
    };
  },
});
