
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import ToastService from "@/core/services/ToastService";
import { Site } from "@/core/model/site";
import { displayErrors } from "@/core/helpers/errors";

export default defineComponent({
  name: "admin-mutual-site-form-modal",
  emit: ["load-mutual-site"],
  components: {},
  props: {
    action: { type: String, default: "new" },
    site: { type: Object, default: {} as Site },
    open: Number,
  },
  watch: {
    open: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != undefined && oldVal != undefined) {
          this.checkModalType();
        }
      },
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const addMutualSiteModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingLocation = ref<boolean>(false);
    const options = ref<Array<Location>>([]);
    const formData = ref({
      name: "",
      protocol: "",
      domain: "",
      physical_uri: "",
      mutual: "",
      mutual_site: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 100,
          message: "Este campo admite máximo 100 caracteres",
          trigger: "change",
        },
      ],
      protocol: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 5,
          message: "Este campo admite máximo 5 caracteres",
          trigger: "change",
        },
      ],
      domain: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 150,
          message: "Este campo admite máximo 150 caracteres",
          trigger: "change",
        },
      ],
      physical_uri: [
        {
          max: 64,
          message: "Este campo admite máximo 64 caracteres",
          trigger: "change",
        },
      ],
    });

    const checkModalType = () => {
      if (props.action == "edit") {
        loadFormData();
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          formData.value.mutual = route.params.uuid as string;
          loading.value = true;
          store
            .dispatch(
              props.action == "new"
                ? Actions.ADMIN_SITE_CREATE
                : Actions.ADMIN_SITE_UPDATE,
              formData.value
            )
            .then(() => {
              loading.value = false;
              emit("load-mutual-site");
              ToastService.show(
                props.action == "new"
                  ? "Sitio registrado con éxito"
                  : "Sitio actualizado con éxito",
                "success"
              );
              hideModal(addMutualSiteModalRef.value);
            })
            .catch(() => {
              displayErrors(store, "AdminMutualesModule");
              loading.value = false;
            });
        } else {
          ToastService.show(
            "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
            "error"
          );
          return false;
        }
      });
    };

    const loadFormData = () => {
      if (typeof props.site.name !== "undefined") {
        formData.value.name = props.site.name;
        formData.value.protocol = props.site.protocol;
        formData.value.domain = props.site.domain;
        formData.value.physical_uri = props.site.physical_uri;
        formData.value.mutual = props.site.mutual;
        if (props.action == "edit") {
          formData.value.mutual_site = props.site.uuid;
        }

        return true;
      }
      return false;
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addMutualSiteModalRef,
      options,
      loadingLocation,
      checkModalType,
    };
  },
});
